@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Ubuntu', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
i {
  color: var(--text-color) !important;
  margin: 0 !important;
}

.toast-styling {
  color: var(--text-color);
  background-color: var(--foreground);
  min-width: 140px;
  min-height: 48px;
  padding: 8px 16px;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 550px;
}
.page-not-found h2 {
  color: #fff;
  font-size: 80px !important;
  font-weight: 700;
  margin-bottom: 0px !important;
  line-height: 80px !important;
  text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbbbbb,
    0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgba(0, 0, 0, 0.1),
    qety 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3),
    0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
.page-not-found p {
  font-size: 24px;
  max-width: 500px;
  margin-top: 30px !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*===>> NOT AUTHENTICATED <<===*/
.index-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--foreground);
}
.logo {
  height: 65px;
  position: relative;
  top: 250px;
}
.index-top-section {
  /* background-image: var(--background-home-image); */
  background: var(--primary-gradient);
  min-height: 500px;
  background-size: cover;
  background-position: center;
}
.index-container {
  max-width: 74rem;
  margin: 0px auto;
  height: 850px !important;
}
.index-container2 {
  max-width: 74rem;
  margin: 0px auto;
}
.bitriel-mock-up {
  width: 900px;
  position: absolute;
  right: -300px;
  /* top: 40px; */
}
.bitriels-apps {
  width: 900px;
  position: relative;
  left: 120px;
}
.apps-title-container {
  width: 70%;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.coin-transparent {
  position: absolute;
  width: 676px;
  left: -600px;
  opacity: 0.1;
}
.create-wallet-img {
  height: 40px;
}
.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 90px 0;
  width: 75%;
}
.welcome-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 120px 0;
  width: 90%;
}
.welcome-message {
  font-size: 48px;
  color: #fff;
  font-weight: bold;
}
.welcome-sub-message {
  font-size: 36px;
  color: #fff !important;
  margin-top: 10px !important;
}
.welcome-sub-message-2 {
  font-size: 24px;
  color: #fff !important;
  margin-top: 10px !important;
}
.welcome-sub-message-3 {
  font-size: 21px;
  color: #fff !important;
  margin-top: 10px !important;
  margin-top: 2rem !important;
}
.create-restore-buttons {
  padding-top: 50px;
}
.button-connect {
  width: 30px;
  margin: 0px;
}
.apps-section {
  /* width: 70%; */
  min-height: 34vh;
  font-size: 18px;
  margin-top: 60px !important;
  padding: 120px 0px;
}
.apps-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-footer {
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}
.index-footer i {
  font-size: 30px;
  margin-right: 20px !important;
  cursor: pointer;
  transition: 0.3s;
  color: var(--text-color);
}
.index-footer i:hover {
  color: #444444;
}

/*===>> END NOT AUTHENTICATED <<===*/
/*===>> SIDEBAR <<===*/
.github-icon {
  font-size: 20px;
  position: relative;
  /* color: var(i); */
  right: 5px;
}
.github {
  position: relative;
  top: -5px;
}
.wrapper {
  margin-left: 250px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  /* background: linear-gradient(135deg, #13f1fc 0%, #0470dc 100%) !important; */
  animation: gradient 15s ease infinite;
}
.container {
  max-width: 60rem !important;
  margin: 0 auto !important;
}
/* .home-container {
  width: 50rem;
  margin: auto;
} */
.wrapper .wrapper-sub-background {
  min-height: 100vh;
  padding: 50px 0;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  animation: gradient 15s ease infinite;
  /* background-color: rgba(243, 243, 243, 0.911); */
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--menu-gradient) !important;
}
.ant-layout-sider {
  background: var(--sidebar) !important;
  box-shadow: rgba(89, 89, 189, 0.15) 0px 2px 5px 0px,
    rgba(93, 89, 187, 0.05) 0px 1px 1px 0px;
}
.ant-menu-item {
  height: 55px !important;
}
/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none !important;
} */

/* .sidebar-sider .ant-layout-sider-children {
  border-radius: 0 30px 30px 0 !important;
} */
.sidebar-sider .ant-layout-sider-children,
.sidebar-menu {
  background-color: var(--sidebar) !important;
}
.sidebar-sider .ant-menu-item-selected::after {
  height: 25px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.sidebar-logo {
  width: auto;
  height: 40px;
  margin: 20px 0;
}
.sidebar-itemTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-gray) !important;
}
.sidebar-top {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 !important;
  flex: 1 1 !important;
  min-height: 55vh;
}
.sidebar-itemActive {
  color: #f37747 !important;
}
.sidebar-itemIcon {
  filter: grayscale(100%);
}
.mobile-drawer-header {
  padding: 15px 15px;
  padding-top: 8px;
  background-color: var(--background) !important;
  display: none !important;
}

.mobile-drawer {
  overflow: hidden !important;
}
.ant-drawer-body {
  padding: 0 !important;
}

/*===>> END SIDEBAR <<===*/
/*===>> HOME <<===*/
.home-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 38.832px;
  position: absolute;
  top: 10px;
}
.home-spacing {
  padding: 16px 0;
}
.home-btn-wrapper img {
  padding: 15px;
  border-radius: 25px;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.home-btn-wrapper:hover,
.home-btn-wrapper-2:hover {
  filter: brightness(0.8);
  -webkit-filter: brightness(0.8);
}
.home-btn-wrapper-2 {
  padding: 20px !important;
  border-radius: 18px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--orange-gradient-icon) !important;
}
.home-connect-evm img {
  background-color: #03a8f421;
}
.home-connect-evm p {
  font-weight: 500;
  color: #03a9f4 !important;
}
.home-create-wallet img {
  background-color: var(--orange-gradient-icon);
}
.home-create-wallet p {
  font-weight: 500;
  color: var(--primary) !important;
}
.home-restore-wallet img {
  background-color: var(--red-gradient-icon);
}
.switch-account {
  background-color: var(--orange-gradient-icon);
  width: 45px;
  height: 45px;
  border-radius: 18px;
}
.switch-account p {
  font-weight: 500;
  color: var(--primary) !important;
}
.home-restore-wallet p {
  color: var(--secondary) !important;
}
.modal-select-account-item {
  background-color: var(--background);
  border: 2px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.5s all;
  padding: 14px 16px;
  margin: 8px 0;
}
.modal-select-account-item:hover {
  border-color: var(--primary) !important;
}
.modal-select-account-item p {
  font-weight: 500;
}

.wallet-username {
  text-overflow: ellipsis; /* IE, Safari (WebKit) */
  overflow: hidden; /* don't show excess chars */
  white-space: nowrap; /* force single line */
  max-width: 80px;
}
/*===>> END HOME <<===*/
/*===>> WALLET <<===*/
.wallet-tabs {
  text-align: center;
  border-radius: 18px;
  transition: 0.3s all;
  padding: 10px;
  cursor: pointer;
}
.wallet-tabs-active {
  background-color: var(--active-color);
  font-weight: bold;
}
.receive-address-container {
  background-color: var(--background);
  padding: 16px;
  border-radius: 8px;
}
.receive-address {
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
}
/*===>> END WALLET <<===*/
/*===>> CREATE WALLET <<===*/
.each-mnemonic-word {
  background-color: #03a9f4;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 4px;
}
.each-mnemonic-word p {
  font-size: 16px;
  font-weight: 500;
  color: #f5f5f5 !important;
}
.create-wallet-step .ant-steps-item-title {
  color: var(--text-color) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--primary-gradient) !important;
  border: 1px solid var(--primary) !important;
}

/*===>> END CREATE WALLET <<===*/
/*===>> RESTORE WALLET <<===*/
.restore-wallet-card {
  font-size: 16px;
  text-align: center;
  border-radius: 18px;
  border: 2px solid var(--primary);
  cursor: pointer;
  transition: 0.2s;
  padding: 10px 20px;
  margin: 16px;
  margin-bottom: 20px;
}
.restore-wallet-card-active {
  background-color: rgba(243, 119, 71, 0.21);
}
.restore-wallet-btnUpload {
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--primary) !important;
  border: 2px solid var(--primary);
  border-radius: 16px;
  cursor: pointer;
  padding: 10px;
}
/*===>> END RESTORE WALLET <<===*/
/*===>> SETTING <<===*/
.setting__padding {
  width: 60%;
  margin: 0 auto;
}
.setting-social-media {
  margin-top: 20px;
  font-size: 26px;
}
.setting-social-media i {
  margin-right: 15px !important;
  color: var(--text-color);
}
/*===>> END SETTING <<===*/
/*===>> EARN <<===*/
.earn-menu-item {
  height: 100%;
  transition: 0.4s all;
  cursor: pointer !important;
}

.earn-menu-item:hover {
  transform: scale(1.03);
}
/*===>> END EARN <<===*/
/*===>> STAKING <<===*/
.staking-controller {
  border: 1px solid var(--foreground);
  border-radius: 8px;
  padding: 8px;
  transition: 0.5s all;
  cursor: pointer;
  border-radius: 16px !important;
  content: '';
  top: 1px;
  right: 1px;
  z-index: -1;
  min-height: 115px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  background: var(--card-background) !important;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-radius: 16px;
  color: #000 !important;
}
.staking-controller::after {
  box-shadow: rgba(89, 89, 189, 0.15) 0px 2px 5px 0px,
    rgba(93, 89, 187, 0.05) 0px 1px 1px 0px;
  border-radius: 16px !important;
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: -1;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  background: var(--card-background) !important;
  border-radius: 16px;
  color: #000 !important;
}
.staking-controller h3 {
  margin-top: 8px !important;
}
.staking-controller:hover {
  border-color: #03a9f4;
}
.staking-rewardOption {
  border: 2px solid var(--text-color);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s all;
}
.staking-rewardOption:hover {
  border-color: #03a9f4;
}
.staking-rewardOption:hover h3 {
  color: #03a9f4 !important;
}
.staking-nominate {
  background-color: var(--background);
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: all 0.5s;
  cursor: pointer;
  border: 1.6px solid transparent;
}
.staking-nominate:hover {
  border: 1.6px solid #03a9f4;
}
.staking-table {
  color: var(--text-color);
  width: 100%;
}
.staking-table th,
.staking-table td {
  padding: 0.45rem;
  word-break: break-all;
}
.staking-table tr td:first-child {
  width: 30%;
  font-weight: bold;
}
.staking-payee {
  height: 40px;
  border: 2px solid #03a9f4;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0;
}
.staking-payeeItem {
  height: 50px;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0;
  cursor: pointer;
  transition: 0.4s all;
}
.staking-bondUnbond {
  transition: all 0.5s;
  cursor: pointer;
}
.staking-bondUnbond:hover {
  filter: brightness(0.8);
  -webkit-filter: brightness(0.8);
}
/*===>> END STAKING <<===*/
/*===>> LOADING <<===*/
.nodata {
  height: 100%;
  width: 100%;
  background: var(--background-card);
  backdrop-filter: blur(9px) saturate(180%);
  -webkit-backdrop-filter: blur(9px) saturate(180%);
  border-radius: 16px;
  opacity: 1;
  transition: 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  padding: 45% 30%;
}
.connecting-node {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #22272e;
}
.about a {
  color: var(--text-gray) !important;
  font-weight: 400;
  cursor: pointer;
}
.about a:hover {
  color: var(--primary) !important;
}

.dark-mode {
  color: var(--text-gray) !important;
  font-weight: 600;
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: -6px;
}
.about-container {
  padding-top: 100px;
}
/*===>> END LOADING <<===*/

.connect-evm-2 {
  display: grid;
  justify-content: end;
  align-items: flex-end;
  margin-right: 350px;
  margin-bottom: 30px;
}
.connect-evm {
  position: absolute;
  right: 355px;
  top: 42px;
  z-index: 1;
}

.account-active {
  background-color: #03a9f4;
  padding: 1px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: -9px;
  border: 2px solid #fff;
  animation: online 2s infinite;
}

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 #03a9f4;
  }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes online {
  0% {
    -moz-box-shadow: 0 0 0 0 #03a9f4;
    box-shadow: 0 0 0 0 #03a9f4;
  }
  70% {
    -moz-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@-webkit-keyframes staking {
  0% {
    -webkit-box-shadow: 0 0 0 0 #f27649;
  }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes staking {
  0% {
    -moz-box-shadow: 0 0 0 0 #f27649;
    box-shadow: 0 0 0 0 #f27649;
  }
  70% {
    -moz-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.home-wallet-container {
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
}
.swicth-icon,
.copy-icon {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  padding-top: 5px;
}
.swicth-icon {
  background: var(--orange-gradient-icon) !important;
  border: 1px solid var(--primary);
}

.copy-icon {
  background: var(--red-gradient-icon) !important;
  border: 1px solid var(--secondary);
}
.copy-icon span {
  color: var(--secondary) !important;
}
.home-buttons-options-container {
  padding-top: 20px;
}
.address-name {
  font-size: 24px;
}
.home-account-type {
  font-size: 14px !important;
}

.home-token-list {
  display: flex;
  justify-content: space-between;
}
.connect__wallet {
  cursor: pointer;
}
.my-portfolio {
  width: 110px;
  height: 110px;
  padding: 20px;
  border-radius: 45px;
  background: var(--orange-gradient-icon);
}
.portfolio-money {
  font-size: 40px !important;
  color: var(--primary) !important;
}
.portfolio-equalto {
  font-size: 14px !important;
  color: var(--text-gray) !important;
}
.portfolio-sel-transparent {
  width: 150px !important;
  position: absolute;
  opacity: 0.1;
  right: 0px;
  top: 5px;
}
.portfolio-visible-wallet {
  position: relative;
  top: -20px;
  cursor: pointer;
  font-size: 20px;
  color: var(--primary) !important;
}
.portfolio-staking {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.portfolio-staking-token {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.portfolio-staking-token p {
  font-size: 14px !important;
}
.portfolio-overall {
  color: var(--primary) !important;
}
.portfolio-validator-name {
  color: var(--text-gray) !important;
  font-size: 14px !important;
  margin-left: 10px;
}
.graph-container {
  width: 390px;
  margin: auto;
  padding-bottom: 40px;
}

.react-qrcode-logo {
  border-radius: 16px !important;
}
.swap-icon {
  height: 60px;
  cursor: pointer;
  position: relative;
  top: 27px;
}
.swap-icon:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out 0s;
}
.swap-icon:not(:hover) {
  transform: rotate(-180deg);
  transition: all 0.3s ease-in-out 0s;
}
.swap-form-container {
  padding: 30px 60px;
}

.token-slider-amount {
  width: 50% !important;
}
.liquidity-container {
  margin-top: 30px;
}
.swap-rates {
  color: var(--secondary) !important;
  position: relative;
  top: 30px;
}
.swap-token-details {
  position: relative;
  /* top: 45px; */
}

.staking-overview {
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 150px !important;
}
.is-staking {
  font-size: 20px !important;
  color: var(--primary) !important;
  animation: staking 2s infinite;
  border-radius: 50px;
  width: 15px;
  height: 15px;
}
.details {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: center;
  cursor: pointer;
}
.cards-footer {
  background: var(--orange-gradient-icon) !important;
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 97% !important;
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  justify-content: center;
  padding-top: 12px;
  font-size: 16px !important;
  font-weight: 600;
}
.cards-footer2 {
  background: var(--orange-gradient-icon) !important;
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 95% !important;
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  justify-content: center;
  padding-top: 12px;
  font-size: 16px !important;
  font-weight: 600;
}
.cards-footer h5 {
  color: var(--primary) !important;
  font-weight: 600;
}
.card-background-launchpad {
  position: relative;
  margin: 0 !important;
  padding: 0;
  width: 100% !important;
  left: -34px;
  top: -38px;
}
.background-launchpad-img {
  height: 250px !important;
  width: 471px !important;
  border-radius: 16px 16px 0px 0px;
}
.launchpad-logo-container {
  position: absolute;
  top: 200px;
  left: 51%;
  margin-left: -50px;
}
.launchpad-logo {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  background-color: var(--background);
  border: 2px solid var(--secondary);
  padding: 5px;
}
.launchpad-bio {
  color: var(--secondary) !important;
  font-size: 14px !important;
}
.launchpad-desc {
  color: var(--text-gray) !important;
  padding-top: 10px !important;
}
.launchpad-desc-container {
  margin-bottom: 30px;
}

.launchpad-desc-container p {
  color: var(--text-gray) !important;
  padding-top: 10px !important;
}
.launchpad-desc-container2 {
  padding: 10px 60px;
}

.launchpad-desc-container2 ul {
  line-height: 2.5;
}

.launchpad-banner {
  height: 340px;
  width: 100%;
  border-radius: 16px;
}
.raise-goal {
  color: var(--primary) !important;
}
.black-background {
  background: var(--primary-gradient) !important;
}
.market-title {
  font-size: 64px !important;
  color: #fff !important;
  font-weight: 600;
  position: relative;
  top: -60px;
}
.bitriel-market-place {
  font-size: 128px !important;
  color: rgb(255, 159, 73) !important;
  position: relative;
  top: 30px;
  left: 0;
}
.bitriel-market-img {
  width: 800px;
}

.container2 {
  position: relative;
  top: -200px;
}
.btn {
  margin-top: calc(50% + 25px);
  position: relative;
  display: inline-block;
  width: 277px;
  height: 50px;
  font-size: 1em;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 0.045em;
}

.btn svg {
  position: absolute;
  top: 0;
  left: 0;
}

.btn svg rect {
  stroke: #fff;
  stroke-width: 2;
  stroke-dasharray: 353, 0;
  stroke-dashoffset: 0;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}

.btn span {
  color: #fff !important;
}

.btn:hover svg rect {
  stroke-width: 2;
  stroke-dasharray: 196, 543;
  stroke-dashoffset: 437;
}

.paper-container{
  padding: 30px 60px;
}
.paper{
  background:#fff !important;
  padding: 50px 120px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  line-height: 2.2;
  font-size: 16px !important;
}
.logo-2{
  height: 45px;
}

.ant-layout .footer {
  background: #162126 !important;
  color: #fff;
  position: relative;
  width: 100%;
}
.logo-footer {
  width: 200px;
  position: relative;
  left: -25px;
  margin-bottom: 40px;
}
.footer-head-info {
  font-size: 24px !important;
  font-weight: bold;
  color: #d8d8d8 !important;
}

.container .footer .btn-color {
  background: #1b262c !important;
  color: #f5f5f5;
  font-weight: 600;
  border-radius: 50px !important;
}
.container .footer p {
  color: #d8d8d8;
  font-size: 18px !important;
  font-weight: 700;
}
.ant-layout-footer {
  padding: 20px 0 20px 0;
  background: #00325c !important;
  color: #f5f5f5 !important;
}
.social-media svg {
  font-size: 30px !important;
  margin-left: 0px;
  margin-bottom: 5px !important;
}
.social-media svg {
  background-color: #c1c1c1;
  font-size: 32px;
  padding: 5px;
  margin-right: 10px;
  border-radius: 6px;
  color: #1b262c;
  margin-bottom: 15px;
  transition: .3s;
}
.sub-header-footer {
  font-size: 18px;
  font-weight: normal;
  padding: 6px 0px !important;
}
.sub-header-footer a {
  color: #c1c1c1 !important ;
}
.logo-navbar{
  width: 12rem;
}
.ant-layout-header.navbar-background{
  background: var(--primary-gradient) !important;
}

.navbar-container{
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: auto;
}
.about-header{
  color: #fff !important;
  font-weight: 600;
}
.collapse-container{
  width: 80% ;
  margin: auto;
  padding-top: 3rem;
}
.faq-header{
  font-size: 19px;

}
.panel-faq{
  line-height: 2;
  font-size: 16px;
}
.panel-faq ol ,ul{
  list-style-type: lower-alpha;
  margin-left: 3rem !important;
}
.bitriel-mock-up-about {
  width: 600px;
  position: relative;
  right: 100px;
}
.about-container{
  width: 60%;
  margin: auto;
}
.faq-banner{
  width: 100%;
  position: absolute;
  z-index: -50;
  top: 0;
}
.faq-banner-header{
  font-size: 36px;
  color: #fff !important;
}

/* ================responsive ================ */

@media screen and (max-width: 1600px) {
  .about-container {
    width: 90%;
    margin: auto;
  }
  .bitriel-mock-up-about {
    width: 500px;
    position: relative;
    right: 100px;
  }
  
  .navbar-container{
    width: 95%;
  }
  .bitriels-apps {
    width: 600px;
    position: relative;
    left: 260px;
  }
  .logo {
    position: relative;
    top: 200px;
  }
  .welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 200px 0;
    width: 75%;
  }
  .coin-transparent {
    position: absolute;
    left: -300px;
  }
  .container {
    max-width: 45rem !important;
  }
  .about-container {
    padding-top: 50px !important;
  }
  .home-btn-wrapper img {
    width: 70px;
    height: 70px;
    border-radius: 25px;
  }
  .connect-evm {
    margin-right: 250px;
  }
  .bitriel-mock-up {
    width: 500px;
    position: absolute;
    right: -100px;
    top: 90px;
  }
  .index-container {
    max-width: 70rem;
    margin: 0px auto;
    height: 850px !important;
  }
}

@media screen and (max-width: 1200px) {
  .market-title {
    font-size: 55px !important;
  }
  .bitriel-market-img {
    width: 520px;
  }
  .index-container {
    padding: 20px 120px;
    /* height: 747px !important; */
  }
  .welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 170px 0;
    width: 80% !important;
  }
  .logo {
    position: relative;
    top: 150px;
  }
  .bitriels-apps {
    width: 500px;
    margin: auto;
  }
  .connect-evm {
    margin-right: 27px;
  }
  .about-container {
    padding-top: 320px !important;
  }
  .my-portfolio {
    width: 90px;
    height: 90px;
    padding: 12px;
    border-radius: 36px;
  }
  .portfolio-money {
    font-size: 27px !important;
  }
  .graph-container {
    width: 230px;
  }
  .swap-form-container {
    padding: 30px;
  }
  .background-launchpad-img {
    height: 200px !important;
    width: 346px !important;
  }
  .launchpad-logo-container {
    position: absolute;
    top: 140px;
  }
  .cards-footer2 {
    width: 90% !important;
  }
  .portfolio-sel-transparent {
    width: 130px !important;
  }
  .receive-address {
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    margin: 0;
  }
  .sidebar-top {
    min-height: initial;
  }
  .bitriel-mock-up {
    width: 460px;
    position: absolute;
    right: -100px;
    top: 150px;
  }
  .index-container {
    padding: 20px 50px;
    height: 747px !important;
  }
  .welcome {
    width: 100%;
  }
  .bitriels-apps {
    width: 350px;
    margin: auto;
  }
  .welcome-message {
    font-size: 40px;
  }
  .logo {
    height: 45px !important;
  }
}
@media screen and (max-width: 812px) {
  .bitriel-market-img {
    width: 390px;
  }
  .about-container {
    padding-top: 460px !important;
  }
  .container {
    padding: 0 16px;
  }
  .mobile-drawer-header {
    display: flex !important;
  }
  .index-container {
    padding: 0 24px;
  }
  .welcome-message {
    font-size: 40px;
  }
  /* .apps-section {
    width: 80%;
  } */
  .index-footer {
    display: flex;
    justify-content: center;
  }
  .connect-evm {
    margin-right: 60px;
  }
}

@media screen and (max-width: 600px) {
  .faq-banner-header {
    font-size: 24px;
    color: #fff !important;
  }
  
  .collapse-container {
    width: 93%;
    margin: auto;
    padding-top: 2rem;
  }
  .about-header{
    font-size: 14px;
  }
  .navbar-container{
    width: 100%;
  }
  .logo-navbar {
    width: 6rem;
  }
  .bitriel-market-place {
    font-size: 85px !important;
  }
  .container2 {
    text-align: center;
    position: relative;
    top: -168px;
  }
  .market-title {
    font-size: 37px !important;
  }
  .bitriel-market-place {
    font-size: 85px !important;
  }
  .staking-overview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 27px !important;
  }
  .mobile-drawer-header {
    display: flex !important;
  }
  .wrapper .wrapper-sub-background {
    padding: 20px 0px;
  }
  .swap-form-container {
    padding: 0px;
  }
  .connect-evm {
    margin-right: 17px;
    display: none;
  }
  .about-container {
    padding-top: 0px !important;
  }
  .welcome-message {
    font-size: 26px;
  }
  .welcome-sub-message {
    font-size: 16px;
  }
  .welcome-sub-message-2 {
    text-align: center;
    font-size: 18px !important;
    margin-top: -20px !important;
  }
  .bitriel-market-img {
    width: 320px;
    position: relative;
    top: -50px;
  }
  .bitriel-mock-up {
    width: 300px;
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .create-wallet-img {
    height: 30px;
  }
  .apps-section {
    width: 100%;
    padding-top: 20px;
  }
  .apps-section h3 {
    font-size: 16px !important;
  }
  .bitriels-apps {
    width: 350px;
    margin-top: 50px;
    position: relative;
    left: 0;
  }
  .apps-title-container {
    width: 89%;
  }
  .coin-transparent {
    display: none;
  }
  .welcome {
    margin: 0;
    padding: 20px 0px;
    width: 100% !important;
  }
  .logo {
    height: 40px !important;
    position: relative;
    top: 20px;
  }
  .index-container {
    height: 800px !important;
  }
}
